













































import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    companyId: {
      type: String,
      required: true,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      infoDialog: false,
      error: false as boolean | number,
    });

    const model = reactive({
      name: "",
      content: "",
      files: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 500:
          return `${root.$tc("panel.event.company.message.error500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let sendMessage = false;
      const files: string[] = [];

      if (model.files && model.files.length) {
        for (const file of model.files) {
          const formData = new FormData();
          formData.append("file", file);
          await axiosInstance
            .post(`file/company/panel/${root.$route.params.id}`, formData, {
              headers: {
                event: root.$route.params.id,
              },
            })
            .then(({ data: { id } }) => {
              files.push(id);
              sendMessage = true;
            })
            .catch((e) => {
              sendMessage = false;
              root.$store.commit("snackbar/PUSH_MESSAGE", {
                id: uuid(),
                color: "error",
                message: root.$tc("layout.misc.cannotSaveFile"),
              });
              console.log(e);
            });
        }
      }

      const data = {
        name: model.name,
        content: model.content,
        files: files || undefined,
      };
      if (sendMessage) {
        axiosInstance
          .post(`company/${props.companyId}/message`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.company.message.success"),
            });

            model.name = "";
            model.content = "";
            model.files = [];

            setTimeout(() => {
              emit("close");
            }, 500);
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          });
      }
    };

    return { state, model, onSubmit };
  },
});
